import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import NavigationBar from '../NavigationBar';
import Sidebar from '../Sidebar';
import { useStateValue } from '../../ContextApi/StateProvider';
import { CLASS } from '../../api/urls/institute';
import useApi from '../../api/useApi';
import { USER_PROFILE } from '../../api/urls/profile';
import { ALL_INSTITUTES } from '../../api/urls/institute';
import { ROLE } from '../../api/urls/baseUrls';

const Layout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [{ token, classSections, validRole, user }, dispatch] = useStateValue();

  const [classState, getClassSections] = useApi();
  const [profileRes, getProfile] = useApi();
  const [instituteRes, getInstitutes] = useApi();
  const [roleState, sendRole] = useApi();

  useEffect(() => {
    if (token && classSections.length === 0 && location.pathname !== '/login') {
      getProfile(USER_PROFILE, 'GET');
      getClassSections(CLASS, 'GET');
      getInstitutes(ALL_INSTITUTES, 'GET');
    }
  }, [location, token]);

  useEffect(() => {
    if (!token && location.pathname !== '/healthcheck' && !location.pathname.includes('html')) {
      if (location.search.includes('parentAdmissionRes')) history.replace('/login' + location.search);
      else history.replace('/login');
    }
  }, [token]);

  useEffect(() => {
    if (!profileRes.isLoading && !profileRes.isError && profileRes.data) {
      if (!user)
        dispatch({
          type: 'USER',
          user: profileRes.data,
        });
      sendRole(ROLE, 'PUT', {
        // child_id: '5efa280a423118ba665956d5',
        child_id: profileRes.data.roles[0].role === 'pa' ? profileRes.data.roles[0].child_id : '',
        // institute_id: '5e5fb81471f4781624da8c00',
        institute_id: profileRes.data.roles[0].institute_id,
        refreshToken: localStorage.refresh_token,
        role: profileRes.data.roles[0].role,
        // role: 'te',
      });
    }
  }, [profileRes]);

  useEffect(() => {
    if (!roleState.isLoading && !roleState.isError && [201, 202].includes(roleState.status)) {
      dispatch({
        type: 'VALID_ROLE',
        validRole: true,
      });
    }

    if (roleState.isError) {
      Cookies.remove('auth');
      dispatch({
        type: 'TOKEN',
        token: null,
      });
    }
  }, [roleState]);

  useEffect(() => {
    if (!classState.isLoading && !classState.isError && classState.data) {
      dispatch({
        type: 'CLASS_SECTION',
        payload: classState.data,
      });
    }
  }, [classState]);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      dispatch({
        type: 'INSTITUTES',
        payload: instituteRes.data.response,
      });
    }
  }, [instituteRes]);

  return (
    <div className="flex flex-col ">
      <div className="">{token && <NavigationBar />}</div>
      {!profileRes.isLoading && (
        <div className={token && 'flex  rounded-3xl -mt-1 -ml-1'} style={{ background: '#eee' }}>
          {token && validRole && <Sidebar />}

          <div
            className={`rounded-tl-2xl -mt-1 z-20 ${token ? 'w-full md:w-82pc' : 'w-full'}`}
            style={{
              paddingTop: token && '1%',
              paddingLeft: token && '1%',
              background: '#eee',
            }}
          >
            {children}
          </div>

          {token && (
            <div className="hidden absolute right-3 z-30 md:flex gap-x-2 items-center mt-2.5">
              {/* <span className="text-textLight text-xxs font-normal">Powered by</span> */}
              <img
                src="https://roledin-images-bucket.s3.ap-south-1.amazonaws.com/roledin.png"
                className="h-4"
                alt="roledin"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
